import { Flex, Modal, Pill, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { ScheduleCallbackForm, WrappingCountdownText } from "../../call-controls";
import { useSession } from "../../sdk";
import classes from "./EditSessionSummaryModal.module.css";
import { SessionSummaryForm } from "./SessionSummaryForm";

interface EditSessionSummaryModalProps {
    endSession: (reason: "AgentTerminatedWrapping" | "WrappingTimeExpired") => Promise<void>;
    onDismiss: ({ checkSummary }: { checkSummary?: boolean }) => void;
    loading: boolean;
    validAccountNumber: boolean;
    onExpired: () => Promise<void>;
    opened: boolean;
}

export function EditSessionSummaryModal({
    endSession,
    onDismiss,
    validAccountNumber = false,
    loading,
    opened,
    onExpired,
}: EditSessionSummaryModalProps): JSX.Element | null {
    const [view, setView] = useState<"edit-summary" | "schedule-callback">("edit-summary");
    const { wrappingState } = useSession();

    useEffect(() => setView("edit-summary"), [opened]);

    const { totalSeconds } = useTimer({
        expiryTimestamp: new Date(wrappingState?.expirationTimestamp ?? Date.now()),
        autoStart: true,
        onExpire: () => void onExpired(),
    });

    /** Prevent mantine's attempt to close the modal when the outside overlay is clicked */
    const interceptClose = () => null;
    const callbackScheduleHandler = () => {
        onDismiss({ checkSummary: false });
    };

    return (
        <Modal
            centered
            classNames={{
                inner: classes.modalInner,
                overlay: classes.modalOverlay,
                content: classes.modalContent,
            }}
            onClose={interceptClose}
            opened={opened}
            transitionProps={{
                transition: "pop",
            }}
            overlayProps={{
                backgroundOpacity: 0.1,
            }}
            size="32rem"
            withCloseButton={false}
        >
            <Flex id="modal-heading" align="center" justify="space-between" mb="1rem">
                <Text className={classes.modalTitle} fw="var(--mantine-font-weight-normal)" fz="md">
                    {view === "schedule-callback" ? "Schedule Callback" : "Session Summary"}
                </Text>
                <Pill classNames={{ root: classes.pillRoot, label: classes.pillLabel }} fz="xxs" size="xs">
                    <WrappingCountdownText
                        appendUnit={false}
                        format="m:ss"
                        ff="monospace"
                        fw="var(--mantine-font-weight-heavy)"
                        size="xxs"
                        totalSeconds={totalSeconds}
                    />
                    {" sec"}
                </Pill>
            </Flex>
            {view === "schedule-callback" ? (
                <ScheduleCallbackForm
                    onBackClick={() => setView("edit-summary")}
                    afterSubmitClickDefault={callbackScheduleHandler}
                />
            ) : (
                <SessionSummaryForm
                    validAccountNumber={validAccountNumber}
                    setView={setView}
                    endSession={endSession}
                    loading={loading}
                />
            )}
        </Modal>
    );
}
