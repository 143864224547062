import { getLogger } from "@expert/logging";
import { setAppcuesIdentifier, setFullStoryIdentifier, trackAppcuesEvent } from "@expert/monitoring";
import { GuideCard, useSolveGuideStore } from "@expert/solve-tools";
import { Container, Flex } from "@mantine/core";
import { AnalyticsProvider } from "@soluto-private/eventualize-react";
import { useSetDraggableBounds } from "@soluto-private/expert-workspace-notepad";
import { useEffect, useMemo, useRef, useState } from "react";
import { ErrorBoundary, withErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { env } from "../../../config";
import { useDeviceActivationStore, useSetupActivationGuide } from "../../activation";
import { CallControlsManager } from "../../call-controls";
import { useCallDetailsStore } from "../../call-details";
import { useVerifiedCustomerStore } from "../../customer-info";
import { CustomerPanel, useVerificationFlowStore } from "../../customer-panel";
import { useGaiaSalesMessage } from "../../gaia-client/hooks/useGaiaSalesMessage";
import { NotificationContainer } from "../../notification/NotificationContainer";
import {
    MicroLearning,
    useCurrentStepStore,
    useGaiaMessageStore,
    useHomeProductSessionStore,
    useOtherProductSessionStore,
} from "../../sales-tools";
import { useDisclosureStepStore } from "../../sales-tools/disclosures/store/DisclosureStepStore";
import { useOfferChecklistStore } from "../../sales-tools/home-product/stores/useOfferChecklistStore";
import { WidgetContainer } from "../../sales-tools/widgets";
import { ConfettiAnimation } from "../../sales-tools/reactions/Confetti";
import {
    DevDebugTools,
    isTrainingTask,
    isVoiceTask,
    sdkEventBus,
    useActiveTask,
    useAgentSdk,
    useAgentStore,
    usePartner,
    useSession,
} from "../../sdk";
import { SessionSummary, DeprecatedSessionSummary } from "../../session-summary";
import { RenderError, useFeatureFlag, userCache } from "../../shared-utils";
import { PlatformTimeline } from "../../timeline";
import { Troubleshooting, useTroubleshootingStore } from "../../troubleshoot";
import { WorkspaceHeader } from "../header";
import { TrainingModal } from "../training";
import { useSalesChecklistEventListener } from "../../sales-tools/sales-checklist/useSalesChecklistEventListener";
import styles from "./workspace.module.css";

const logger = getLogger({ module: "Workspace" });

const SessionSummaryHandled = withErrorBoundary(SessionSummary, { FallbackComponent: RenderError });
const DeprecatedSessionSummaryHandled = withErrorBoundary(DeprecatedSessionSummary, { FallbackComponent: RenderError });
const PlatformTimelineHandled = withErrorBoundary(PlatformTimeline, { FallbackComponent: RenderError });
const GuideCardHandled = withErrorBoundary(GuideCard, { FallbackComponent: RenderError });
const TrainingModalHandled = withErrorBoundary(TrainingModal, { FallbackComponent: RenderError });
const CallControlsManagerHandled = withErrorBoundary(CallControlsManager, { FallbackComponent: RenderError });
const MicroLearningModalHandled = withErrorBoundary(MicroLearning, { FallbackComponent: RenderError });

export function Workspace(): JSX.Element {
    const activeTask = useActiveTask();
    const navigate = useNavigate();
    const sdk = useAgentSdk();
    const { agentId, agentName, activity: currentActivity } = useAgentStore((state) => state);
    const { isTroubleshooting, setIsTroubleshooting } = useTroubleshootingStore();
    const activeVoiceTask = useMemo(() => {
        return isVoiceTask(activeTask) ? activeTask : null;
    }, [activeTask]);
    const activeTrainingTask = useMemo(() => {
        return isTrainingTask(activeTask) ? activeTask : null;
    }, [activeTask]);
    const { employeeId, userEmail, identity, givenName, familyName } = userCache;
    const session = useSession();
    const sessionId = session.id;
    const isCustomerSession = session.kind === "with-customer";
    const callSid = activeVoiceTask?.agentCallId;
    const partner = usePartner();
    const activeTaskIdentities = activeTask ? { TaskId: activeTask.id } : {};
    const activeTaskExtra = activeTask
        ? {
              taskType: activeTask.type,
              partner: activeTask.partner,
          }
        : {};

    const { guide, isActivationGuideEnabled, isGuideOpen, guideCommand, closeGuide } = useSetupActivationGuide({
        isCustomerSession,
        partner,
        activeVoiceTask,
        logger,
    });

    const { data: isMicroLearningEnabled } = useFeatureFlag("EWP-isMicroLearningEnabled", { partner });
    const { data: isTrainingEnabled } = useFeatureFlag("EWP-isTrainingEnabled", { partner });
    const { data: isSalesWidgetsEnabled } = useFeatureFlag("Sales-isWidgetsEnabled", { partner });
    const { data: isUpdatedEndSessionFlowEnabled } = useFeatureFlag("isUpdatedEndSessionFlowEnabled", { partner });

    const [hasCallEnded, setHasCallEnded] = useState(false);
    const hasPendingCallback = session.callbackState?.callbackType === "CallbackNow";
    const displaySummary =
        isCustomerSession &&
        (currentActivity === "Wrapping" || activeTask?.status === "wrapping") &&
        !hasPendingCallback;

    useGaiaSalesMessage();
    useSalesChecklistEventListener();
    useEffect(() => {
        const agentOfflineEvent = sdkEventBus.on("agent_activity_changed", (activity) => {
            const activeActivities = [
                "Available",
                "Busy",
                "Default",
                "Technical Issues",
                "Training",
                "Callback Pending",
                "Wrapping",
            ];

            if (!activeActivities.includes(activity)) {
                sdk.logout?.();
                localStorage.clear();
                sessionStorage.clear();
                navigate("/logged-out");
            }
        });

        const technicalIssuesEvent = sdkEventBus.on("technical_issues", () => {
            logger.info("Troubleshooting | open");
            setIsTroubleshooting(true);
        });

        const sessionChangedEvent = sdkEventBus.on("session_changed", () => {
            if (isMicroLearningEnabled) {
                logger.info("MicroLearning triggered");
                setHasCallEnded(true);
            }
        });
        return () => {
            agentOfflineEvent();
            technicalIssuesEvent();
            sessionChangedEvent();
        };
    }, [setIsTroubleshooting, navigate, sdk, isMicroLearningEnabled]);

    useEffect(() => {
        const warnOnClose = (ev: BeforeUnloadEvent) => {
            // skip browser warning if there is no customer session
            if (!isCustomerSession) {
                return;
            }
            ev.preventDefault();
            ev.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", warnOnClose);

        return () => {
            // remove the old listener every time the task id changes or becomes undefined
            window.removeEventListener("beforeunload", warnOnClose);
        };
    }, [isCustomerSession]);

    useEffect(() => {
        setFullStoryIdentifier({ identity: agentName, displayName: agentName, properties: { agentId, employeeId } });
        setAppcuesIdentifier({
            identity: employeeId ?? agentId,
            name: givenName && familyName ? `${givenName} ${familyName}` : agentName,
            email: userEmail ?? identity,
            partner,
        });

        trackAppcuesEvent("Workspace Loaded", {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // rehydrate stores if current session is with a customer and it has not ended
        if (session.metadata.status !== "ended" && session.kind === "with-customer") {
            void useVerificationFlowStore.persist.rehydrate();
            void useCallDetailsStore.persist.rehydrate();
            void useHomeProductSessionStore.persist.rehydrate();
            void useOtherProductSessionStore.persist.rehydrate();
            void useVerifiedCustomerStore.persist.rehydrate();
            void useCurrentStepStore.persist.rehydrate();
            void useDisclosureStepStore.persist.rehydrate();
            void useDeviceActivationStore.persist.rehydrate();
            void useSolveGuideStore.persist.rehydrate();
            void useOfferChecklistStore.persist.rehydrate();
            void useGaiaMessageStore.persist.rehydrate();
        }
    }, [session]);

    const containerRef = useRef(null);
    useSetDraggableBounds(containerRef);

    return (
        <Flex ref={containerRef} className={styles.container}>
            {env.isProdMode ? null : <DevDebugTools />}
            <AnalyticsProvider
                dispatcher={(dispatcher) =>
                    dispatcher
                        .withIdentities({
                            WorkerId: agentId,
                            SessionId: sessionId,
                            ...activeTaskIdentities,
                        })
                        .withExtra({ partner, ...activeTaskExtra })
                }
            >
                <WorkspaceHeader />
                {isSalesWidgetsEnabled && <WidgetContainer />}
                {isTrainingEnabled && activeTrainingTask?.startTimestamp ? (
                    <Flex className={styles.workspaceBody} direction="row" m="2.5rem 3rem">
                        <TrainingModalHandled task={activeTrainingTask} />
                    </Flex>
                ) : (
                    <Flex className={styles.workspaceBody} direction="column">
                        <CallControlsManagerHandled voiceTask={activeVoiceTask} />
                        <Container className={styles.animationContainer} key={sessionId} pos="fixed">
                            <ConfettiAnimation />
                        </Container>
                        {displaySummary &&
                            (isUpdatedEndSessionFlowEnabled ? (
                                <SessionSummaryHandled />
                            ) : (
                                <DeprecatedSessionSummaryHandled />
                            ))}
                        {isActivationGuideEnabled ? (
                            <GuideCardHandled
                                flow={guide}
                                logger={logger}
                                onGuideClose={closeGuide}
                                opened={isGuideOpen}
                                callSid={callSid}
                            />
                        ) : null}
                        <PlatformTimelineHandled guide={guideCommand} isFocused={!isGuideOpen} />
                    </Flex>
                )}
                {/* This error boundary is swallowing up errors. We need to have a better way to show customer panel errors while still the panel */}
                {isCustomerSession ? (
                    <ErrorBoundary fallback={<RenderError />}>
                        <CustomerPanel />
                    </ErrorBoundary>
                ) : null}
                {isMicroLearningEnabled && hasCallEnded && !isCustomerSession && currentActivity === "Available" ? (
                    <MicroLearningModalHandled />
                ) : null}
                {isTroubleshooting ? <Troubleshooting /> : null}
                {isSalesWidgetsEnabled && <NotificationContainer />}
            </AnalyticsProvider>
        </Flex>
    );
}
