import { useEffect, useRef, useState } from "react";
import { Container } from "@mantine/core";
import { useIntersection } from "@mantine/hooks";
import { type TimelineFeatures, useFeatures } from "@expert/features";
import { useTimelineStore } from "../../state";
import { isFromBot, useAutoscroll } from "../../utils";
import { BotIcon } from "../bot-icon";
import { MessageBubble, MessageBubbleHeader, MessageBubbleWrapper } from "../message-bubble";
import { QuickActionGroup, useQuickActionsManager } from "../quick-actions";
import { Feedback } from "../feedback-loader";
import { MessageTag, TextMask } from "./elements";
import { TimelineItemWrapper } from "./TimelineItemWrapper";
import { Separator } from "./Separator";
import type { TimelineItemProps } from "./types";
import timelineClasses from "./TimelineItem.module.css";

export function TimelineItem({
    isLoading,
    isLastMessage,
    isLastProactiveMessage,
    message,
    type,
    logger,
    scrollRef,
    metadata,
    children,
}: TimelineItemProps) {
    const { ref: messageIntersectionRef, entry } = useIntersection({
        root: scrollRef?.current,
        threshold: 1,
    });
    const { start: startAutoscroll } = useAutoscroll(scrollRef);

    const overflowRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);

    const { callSid, setIsMessageUnread } = useTimelineStore();

    const {
        features: { isProactiveEnabled, isMessageRatingEnabled },
    } = useFeatures<TimelineFeatures>();

    const { callDuration, tag, key, timestamp } = message ?? {};

    const isFromAI = isFromBot(type);
    const isLastAIMessage = isFromAI && isLastMessage;

    const isProactiveMessage = isProactiveEnabled && type === "Proactive";
    const shouldShowProactive = isProactiveMessage && isLastProactiveMessage;
    const isBotListening = isProactiveEnabled && !isLoading && isLastAIMessage && !!callSid;

    let shouldShowExpanded = true;
    if (isLastProactiveMessage && !isExpanded && isOverflowing) {
        shouldShowExpanded = false;
    } else if (isLastMessage && type === "SessionSummary" && !isExpanded && isOverflowing) {
        shouldShowExpanded = false;
    }

    const shouldShowMessageTag = tag && isLastProactiveMessage;
    const shouldShowFeedback =
        isMessageRatingEnabled && (type === "Search" || type === "SessionSummary") && shouldShowExpanded;

    const actions = useQuickActionsManager({
        currentMessageType: type,
        messageId: message?.id,
        defaultMessageKey: key,
        isOverflowing,
        isExpanded,
        isLastMessage,
        isLastProactiveMessage,
        setIsExpanded,
    });
    const [isQuickActionsVisible, setIsQuickActionsVisible] = useState<boolean>(actions.length > 0);

    // remove cta buttons if the actions are empty
    useEffect(() => {
        if (!(actions.length > 0)) {
            setIsQuickActionsVisible(false);
        }
    }, [actions, setIsQuickActionsVisible]);

    // check if the proactive bot message is overflowing in the bubble
    useEffect(() => {
        setIsOverflowing(
            overflowRef.current?.scrollHeight
                ? overflowRef.current.scrollHeight > overflowRef.current.clientHeight
                : false,
        );
    }, [overflowRef.current?.scrollHeight, overflowRef.current?.clientHeight]);

    /* Only autoscroll when expanding a message and NOT if a proactive
       message comes in while the expert is scrolled up in the timeline */
    useEffect(() => {
        if (shouldShowExpanded && isOverflowing && isLastAIMessage && !message?.isUnread) {
            startAutoscroll();
        }
    }, [shouldShowExpanded, isOverflowing, isLastAIMessage, message?.isUnread, startAutoscroll]);

    useEffect(() => {
        if (entry?.isIntersecting && message?.isUnread) {
            setIsMessageUnread(message.id, false);
        }
    }, [entry?.isIntersecting, message?.isUnread, message?.id, setIsMessageUnread]);

    // TODO: Clean up as part of TimelineItem refactor
    const messageBubbleWrapperContainer = timelineClasses.messageBubbleAndHeaderWrapper;
    const containerClassName =
        type === "SessionSummary"
            ? `${timelineClasses.sessionSummaryContainer} ${messageBubbleWrapperContainer}`
            : messageBubbleWrapperContainer;

    return (
        <>
            {key === "offCall" && <Separator callDuration={callDuration} timestamp={timestamp} />}
            <TimelineItemWrapper data-testid="timeline-item" isFromAi={isFromAI}>
                <BotIcon
                    isBotListening={isBotListening}
                    isHidden={!isLastAIMessage && !isLoading}
                    isLoading={isLoading}
                />
                {!isLoading && (
                    <Container className={containerClassName}>
                        <MessageBubbleHeader isLastMessage={isLastMessage} type={type} metadata={metadata} />
                        <MessageBubbleWrapper
                            isBotListening={isBotListening}
                            isExpanded={shouldShowExpanded}
                            type={type}
                        >
                            <MessageBubble
                                shouldShowProactive={shouldShowProactive}
                                isLastProactiveMessage={isLastProactiveMessage}
                                overflowRef={overflowRef}
                                messageIntersectionRef={messageIntersectionRef}
                                tag={tag}
                                type={type}
                            >
                                <TextMask isExpanded={shouldShowExpanded}>
                                    {shouldShowMessageTag && <MessageTag tagName={tag} />}
                                    {children}
                                </TextMask>
                                {isQuickActionsVisible && (
                                    <QuickActionGroup
                                        actions={actions}
                                        scrollRef={scrollRef}
                                        setIsQuickActionsVisible={setIsQuickActionsVisible}
                                        logger={logger}
                                    />
                                )}
                                {shouldShowFeedback && <Feedback message={message} />}
                            </MessageBubble>
                        </MessageBubbleWrapper>
                    </Container>
                )}
            </TimelineItemWrapper>
        </>
    );
}
