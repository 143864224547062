import {
    defaultAutoCompleteDelay,
    defaultHelpMeNowButtonText,
    defaultHelpMeNowQuestion,
    type TimelineFeatures,
} from "@expert/features";

export const features: TimelineFeatures = {
    autoCompleteDelay: defaultAutoCompleteDelay,
    helpMeNowButtonText: defaultHelpMeNowButtonText,
    helpMeNowQuestion: defaultHelpMeNowQuestion,
    isAutoCompleteEnabled: false,
    isHelpMeNowEnabled: false,
    isMessageRatingEnabled: false,
    isMultiModalEnabled: false,
    isOzmoMessagingEnabled: false,
    isOzmoToolEnabled: false,
    isProactiveEnabled: false,
    isReactiveCallContextEnabled: false,
    isSalesFAQEnabled: false,
};
