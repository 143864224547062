import { getLogger } from "@expert/logging";
import { getRootDispatcher, updateDispatcherPartner } from "../../analytics";
import { type CaseDetails, type Partner } from "../../shared-types";
import { expertWorkspaceWebSocketEventBus } from "../../websocket";
import type { Session } from "../sessions";
import { assertTaskExists, clearCallbackState, getActiveSession } from "../sessions";
import type { SessionInstance } from "../sessions/session";
import { useSessionStore } from "../sessions/session.store";
import { sessionsOrchestrator } from "../sessions/sessionsOrchestrator";
import type { AgentSdk } from "./agentSdk";
import { scheduleCallbackNow } from "./callbacks";
import { MAX_CALLBACK_WRAPPING_DURATION, TASK_COMPLETE_REASON } from "./config";
import { sdkEventBus } from "./eventBus";
import { callEventsHandler, conferenceEventHandler } from "./events";
import { useAgentStore } from "./store";
import type { Task } from "./task";
import type {
    AgentActivity,
    AudioType,
    CallbackState,
    TaskCancelledReason,
    TaskCompletedReason,
    WrappingEndReason,
    WrappingStartReason,
} from "./types";
import { UNSELECTABLE_AGENT_ACTIVITIES } from "./types";
import type { UpdateParticipantOptions, VoiceTask } from "./voice";
import { isVoiceTask } from "./voice";

const logger = getLogger({
    module: "agentSdkBase",
});

export abstract class AgentSdkBase<TTask extends Task = Task> implements AgentSdk<TTask> {
    constructor() {
        this.subscribeToWebSocketEvents();
    }

    // Expert
    public abstract getDefaultAgentActivity(): AgentActivity;
    public abstract setAgentActivity(activity: AgentActivity, activityUpdatedAt?: number): Promise<void>;
    public abstract getAgentActivity(): AgentActivity;
    public abstract getPartners(): ReadonlySet<Partner>;

    // Task
    public abstract canHandleTaskType(task: Task): boolean;

    public abstract acceptTask(task: TTask): Promise<void>;

    public abstract rejectTask(task: TTask): Promise<void>;

    public abstract wrapupTask(task: TTask, reason: string): Promise<void>;

    public abstract completeTask(task: TTask, reason: TaskCompletedReason): Promise<void>;

    public abstract cancelTask(task: TTask): Promise<void>;

    // Voice
    public abstract hangupCall(task: TTask, reason: string): Promise<void>;

    public abstract sendToSurvey(task: TTask, program: string, confirmitSurveyId: string): Promise<void>;

    public abstract holdCall(task: TTask): Promise<void>;

    public abstract resumeCall(task: TTask): Promise<void>;

    public abstract muteCall(task: TTask, shouldMute: boolean): Promise<void>;

    public completeSession(partner: Partner) {
        sessionsOrchestrator.completeSession(this, partner);
    }

    public async scheduleCallbackNow(callbackDelay: number, callbackMdn: string) {
        return await scheduleCallbackNow(this, callbackDelay, callbackMdn);
    }

    // Callback
    public async callCustomerBack(session: Session): Promise<void> {
        let sessionLogger = logger.child({ action: "callCustomerBack", ...(session as SessionInstance).toLog() });
        if (!session.callbackState) {
            const errorMsg = "Cannot call customer back without a callback state";
            sessionLogger.error(`Call Customer Back | ${errorMsg}`);
            throw new Error(errorMsg);
        }

        sessionLogger.trace("Call Customer Back | execution started");

        const originTask = session.tasks.find((t) => t.id === session.callbackState!.originTaskId);

        if (!originTask) {
            const errorMsg = `Cannot find task ${session.callbackState.originTaskId} for callback`;
            sessionLogger.error(`Call Customer Back | ${errorMsg}`);
            throw new Error(errorMsg);
        }

        sessionLogger = sessionLogger.child({
            taskId: originTask.id, //TODO: toLog method needs to be separated from class for use in this method that may just be an object
            taskName: originTask.name,
            sessionId: originTask.sessionId,
            taskStatus: originTask.status,
            callbackState: session.callbackState,
        });

        assertTaskExists(session, originTask);
        sessionLogger.info("Call Customer Back | creating customer callback for task...");
        const newTaskSid = await this.createCustomerCallbackForTask(
            originTask as unknown as TTask,
            session.callbackState,
        );

        void getRootDispatcher()
            .withExtra({
                partner: originTask.partner,
            })
            .withIdentities({
                TaskSid: newTaskSid,
                PreviousTaskSid: originTask.id,
            })
            .dispatchBusinessEvent("OutboundCallInitiated", {
                destination: session.callbackState.callbackMDN,
                outboundCallType: "TollFreeNumber",
            })
            .then(() => {
                sessionLogger.info(
                    { analyticsEventName: "OutboundCallInitiated" },
                    "Call customer | OutboundCallInitiated analytics dispatched",
                );
            });
    }

    public async callCustomer(mdn: string, { extension }: { extension?: string } = {}): Promise<void> {
        // TODO: guard against ad-hoc call while in a call
        // TODO: guard against ad-hoc call while in certain activities (ie. lunch, break....etc) (Get info from workforce)
        const localLogger = logger.child({ action: "callCustomer" });

        // TODO: Add fn parameter to the message in logger
        localLogger.trace("Call customer | execution started");

        // TODO: rename to current task
        const previousTask = getActiveSession().currentTask;
        const taskLogger = localLogger.child(previousTask?.toLog() ?? {});

        taskLogger.info("Call customer | creating outbound call...");
        const newTaskSid = await this.createOutboundCall(mdn);
        taskLogger.info("Call customer | outbound call created");

        void getRootDispatcher()
            .withExtra({
                partner: previousTask?.partner,
            })
            .withIdentities({
                TaskSid: newTaskSid,
                PreviousTaskSid: previousTask?.id,
            })
            .dispatchBusinessEvent("OutboundCallInitiated", {
                destination: mdn,
                outboundCallType: "TollFreeNumber",
            })
            .then(() => {
                taskLogger.info(
                    { analyticsEventName: "OutboundCallInitiated" },
                    "Call customer | OutboundCallInitiated analytics dispatched",
                );
            });

        if (extension === undefined) return;

        const unsubscribe = expertWorkspaceWebSocketEventBus.on(
            "ws_conference-event",
            ({ callDirection, eventType, taskId }: { callDirection?: string; eventType: string; taskId: string }) => {
                if (callDirection === "outbound" || eventType !== "participant-join" || taskId !== newTaskSid) return;
                unsubscribe();
                void this.sendDtmfDigits(extension);
            },
        );
    }

    // Case Details
    public getCaseDetails(task: Task): CaseDetails | undefined {
        return task.caseDetails;
    }

    public async updateCaseDetails(task: Task, caseDetailsUpdates: Partial<CaseDetails>): Promise<void> {
        return task.updateCaseDetails(caseDetailsUpdates);
    }

    public abstract sendDtmfDigits(digits: string): Promise<void>;

    // Call Redirect & Transfer
    public abstract redirectCallToTFN(task: TTask, redirectTo: string): Promise<void>;
    public abstract redirectCallToSIP(task: TTask, redirectTo: string): Promise<void>;

    public abstract playAudioInConference(task: TTask, audioType: AudioType, partner: Partner): Promise<void>;

    // Conference
    public abstract addConferenceParticipant(
        task: TTask,
        name: string,
        to: string,
        isSip?: boolean,
        sipParams?: Record<string, string>,
    ): Promise<void>;

    public abstract updateParticipant(task: TTask, name: string, options: UpdateParticipantOptions): Promise<void>;

    public abstract removeParticipant(task: TTask, callId: string): Promise<void>;

    public abstract removePendingParticipant(task: TTask, callId: string): Promise<void>;

    public abstract leaveConference(task: TTask): Promise<void>;

    public abstract endConference(task: TTask): Promise<void>;

    // Private/protected
    private subscribeToWebSocketEvents() {
        expertWorkspaceWebSocketEventBus.on("ws_conference-event", conferenceEventHandler);
        expertWorkspaceWebSocketEventBus.on("ws_call-event", callEventsHandler);
    }

    protected onInitialized(
        agentId: string,
        agentName: string,
        activity: AgentActivity,
        activityUpdatedAt: number,
        tasks: TTask[],
    ) {
        const agentStore = useAgentStore.getState();
        agentStore.setAgentId(agentId);
        agentStore.setAgentName(agentName);
        agentStore.setAgentActivity(activity, activityUpdatedAt);

        sessionsOrchestrator.initSessions(this, tasks);

        sdkEventBus.emit("sdk_initialized", { agentId, tasks });

        // If our assigned task is in wrapping and there is no wrapping state, create one
        if (tasks.length && tasks[0].status === "wrapping") {
            if (!useSessionStore.getState().computed.currentSession().wrappingState) {
                void this.startWrapup("UnexpectedWrappingTask");
            }
        }

        // If we already have a pending task that has not been accepted after reload, we need to accept it
        tasks.forEach((task) => {
            if (task.status === "pending" && task.autoAccept) {
                void this.acceptPendingTask(task);
            }
        });

        const currentSession = useSessionStore.getState().computed.currentSession();
        const isWaitingCallback = currentSession.callbackState?.callbackType === "CallbackNow";
        // If we are in an unselectable activity, and we are not wrapping or waiting for a callback, then correct agent activity
        if (this.isAgentInBadActivity(activity) && !currentSession.wrappingState && !isWaitingCallback) {
            void this.setAgentActivity(this.getDefaultAgentActivity(), Date.now());
        }
    }

    /** Is the agent in an unselectable activity while not having any assigned tasks. */
    protected isAgentInBadActivity(activity: AgentActivity) {
        if (!UNSELECTABLE_AGENT_ACTIVITIES.has(activity)) {
            return false;
        }

        if (useSessionStore.getState().computed.activeTasks().length > 0) {
            return false;
        }

        return true;
    }

    protected onActivityChanged(activity: AgentActivity, updatedAt: number) {
        useAgentStore.getState().setAgentActivity(activity, updatedAt);
        sdkEventBus.emit("agent_activity_changed", activity);
    }

    protected onHangupCall(task: VoiceTask) {
        const taskLogger = logger.child({ action: "onHangupCall", ...task.toLog() });
        taskLogger.trace("on Hangup Call | Execution started");
        if (task.status === "pending" && task.callDirection === "outbound") {
            // We hung up before accepting the call, which is rejecting the call
            sdkEventBus.emit("call_rejected", task);
        }
        taskLogger.trace("on Hangup Call | Execution ended");
    }

    protected markConferenceStarted(task: VoiceTask) {
        useSessionStore.getState().setConferenceStarted(task.id, true);
    }

    protected async onTaskCreated(task: TTask) {
        clearCallbackState();
        const taskLogger = logger.child({ action: "onTaskCreated", ...task.toLog() });
        taskLogger.trace("on Task Created | Execution started");
        sessionsOrchestrator.addNewAgentTask(task, this);

        sdkEventBus.emit("task_created", task);
        if (task.status === "pending" && task.autoAccept) {
            await this.acceptTask(task);
        }
        taskLogger.trace("on Task Created | Execution ended");
    }

    protected async onTaskAccepted(task: Task) {
        const taskLogger = logger.child({ action: "onTaskAccepted", ...task.toLog() });
        taskLogger.trace("on Task Accepted | Execution started");

        updateDispatcherPartner(task.partner);

        useSessionStore.getState().updateTaskStatus(task.id, "assigned");
        sdkEventBus.emit("task_assigned", task);
        if (task.onAcceptStatus) {
            await this.setAgentActivity(task.onAcceptStatus);
        }
        await task.onAccepted?.();

        taskLogger.trace("on Task Accepted | Execution ended");
    }

    protected onTaskRejected(task: Task) {
        const taskLogger = logger.child({ action: "onTaskRejected", ...task.toLog() });
        taskLogger.trace("on Task Rejected | Execution started");
        sessionsOrchestrator.onTaskRejected(task, this);

        sdkEventBus.emit("task_rejected", task);
        taskLogger.trace("on Task Rejected | Execution ended");
    }

    protected async onTaskWrapping(task: Task) {
        const taskLogger = logger.child({ action: "onTaskWrapping", ...task.toLog() });
        const session = useSessionStore.getState().getSessionByTaskId(task.id);
        sdkEventBus.emit("task_wrapping", task);

        taskLogger.trace({ session }, "on Task Wrapping | Execution started");
        useSessionStore.getState().updateTaskStatus(task.id, "wrapping");

        try {
            taskLogger.trace({ session }, "on Task Wrapping | Determining if task should be completed");
            // On a callback now we must complete the task and set our state into Callback Pending during wrap
            if (session?.callbackState?.callbackType === "CallbackNow") {
                taskLogger.trace({ session }, "on Task Wrapping | Initiating callback flow");

                taskLogger.trace({ callbackState: session.callbackState }, "onTaskWrapping Callback state");

                await this.setAgentActivity("Callback Pending");
                await this.completeTask(task as TTask, "CallbackInitiated");
            } else {
                taskLogger.trace({ session }, "on Task Wrapping | Starting wrapup flow");
                await this.startWrapup("TaskWrapping");
            }
        } catch (error) {
            taskLogger.error(`Error in onTaskWrapping: ${error?.toString()}`);
        }
        taskLogger.trace("on Task Wrapping | Execution ended");
    }

    protected async onTaskComplete(task: Task, reason: TaskCompletedReason) {
        const taskLogger = logger.child({ action: "onTaskComplete", ...task.toLog() });
        const session = useSessionStore.getState().getSessionByTaskId(task.id);
        const agentActivity = this.getAgentActivity();
        taskLogger.trace({ session, reason }, "on Task Complete | Execution started");
        sessionsOrchestrator.onTaskCompleted(task, reason, this);
        sdkEventBus.emit("task_completed", { task, reason });

        if (session?.callbackState?.callbackType === "CallbackNow" && agentActivity !== "Callback Pending") {
            logger.debug("onTaskComplete Callback state", session.callbackState);

            // If we are in a callback now flow, we should ensure the agent activity is callback pending
            await this.setAgentActivity("Callback Pending");
        }
        taskLogger.trace("on Task Complete | Execution ended");
    }

    protected onTaskCancelled(task: Task, reason: TaskCancelledReason) {
        const taskLogger = logger.child({ action: "onTaskCancelled", ...task.toLog() });
        taskLogger.trace("on Task Cancelled | Execution started");

        sessionsOrchestrator.onTaskCancelled(task, this, reason);
        sdkEventBus.emit("task_cancelled", task);

        taskLogger.trace("on Task Cancelled | Execution ended");
    }

    protected onCallAccepted(task: VoiceTask) {
        sdkEventBus.emit("call_accepted", task);
    }

    protected onHoldCall(task: VoiceTask) {
        useSessionStore.getState().setHold(task.id, true);
        sdkEventBus.emit("call_on_hold", task);
    }

    protected onResumeCall(task: VoiceTask) {
        useSessionStore.getState().setHold(task.id, false);
        sdkEventBus.emit("call_resumed", task);
    }

    protected onMuteCall(task: VoiceTask, shouldMute: boolean) {
        useSessionStore.getState().muteTask(task.id, shouldMute);
        // TODO: Consider having one event for toggle-able events and pass the state to the event payload
        sdkEventBus.emit(shouldMute ? "call_muted" : "call_unmuted");
    }

    protected async acceptPendingTask(task: TTask) {
        const taskLogger = logger.child({ action: "acceptPendingTask", ...task.toLog() });
        if (task.status !== "pending") {
            // TODO: Log error and return w/o exception?
            taskLogger.error("Accept Pending Task | Attempted to accept a task that is not pending");
            return;
        }

        if (isVoiceTask(task) && task.callDirection === "inbound") {
            try {
                taskLogger.trace("Accept Pending Task | Attempting to accept the task...");
                await this.acceptTask(task);
                taskLogger.trace("Accept Pending Task | Successfully accepted the task.");
            } catch (err: unknown) {
                taskLogger.error({ err }, "Accept Pending Task | Failed to accept the task with error: ");
            }
        } else {
            taskLogger.trace("Accept Pending Task | Accepting incoming call without waiting.");
            await this.acceptTask(task);
        }
    }

    public async startWrapup(reason: WrappingStartReason) {
        const startWrapupLogger = logger.child({ action: "startWrapup" });
        startWrapupLogger.trace({ reason }, "Starting wrapup");
        useSessionStore.getState().setWrappingState({
            expirationTimestamp: Date.now() + MAX_CALLBACK_WRAPPING_DURATION,
            startReason: reason,
        });

        await this.setAgentActivity("Wrapping");

        void getRootDispatcher().dispatchBusinessEvent("WrappingStarted", {
            wrappingStartReason: reason,
        });
    }

    public async endWrapup(reason: WrappingEndReason, partner: Partner) {
        logger.trace({ reason }, "Ending wrapup");
        const currentSession = useSessionStore.getState().computed.currentSession();
        const endWrapupLogger = logger.child({ action: "endWrapUp", ...currentSession.toLog() });

        const currentWrappingState = currentSession.wrappingState;

        void getRootDispatcher().dispatchBusinessEvent("WrappingEnded", {
            wrappingEndReason: reason,
        });

        // TODO: Ensure this isn't overly strict
        if (!currentWrappingState && !currentSession.currentTask) {
            endWrapupLogger.error("End Wrapping | Attempted to end wrapping without a wrapping state");
            return;
        }

        useSessionStore.getState().clearWrappingState();
        try {
            if (currentSession.currentTask) {
                const completionReason = reason === "CallbackScheduled" ? "CallbackInitiated" : TASK_COMPLETE_REASON;
                endWrapupLogger.trace({ completionReason }, "on endWrapup | Completing task");
                await this.completeTask(currentSession.currentTask as TTask, completionReason);
            } else if (reason !== "CallbackScheduled") {
                endWrapupLogger.trace({ reason }, "on endWrapup | Completing session not CallbackScheduled");
                this.completeSession(partner);
            }
        } catch (err) {
            endWrapupLogger.error({ err }, "Error completing task or session. Manually cleaning up.");
        }
    }

    protected abstract createCustomerCallbackForTask(task: TTask, callbackState: CallbackState): Promise<string>;

    protected abstract createOutboundCall(callbackMdn: string): Promise<string>;
}
