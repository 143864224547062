import styled from "@emotion/styled";

/** FIXME: remove styled */
export const InitialIcon = styled.div`
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--mantine-color-white);
    border-radius: 50%;
    border: 0.0625rem solid var(--mantine-color-asurion-5);
    justify-content: center;
    align-items: center;
`;

/** FIXME: remove styled */
export const InitialText = styled.div`
    display: flex;
    color: var(--mantine-color-black);
    padding-top: 0.0625rem;
    text-align: center;
    font-size: 0.75rem;
    font-weight: var(--mantine-font-weight-heavy);
`;
