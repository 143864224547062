import type { ExpertAssistMetadata, MessageType } from "../../shared-types";
import { SessionSummaryHeader } from "./custom-messages/SessionSummary";

export function MessageBubbleHeader({
    isLastMessage,
    type,
    metadata,
}: {
    isLastMessage: boolean;
    type: MessageType;
    metadata?: ExpertAssistMetadata;
}) {
    if (type === "SessionSummary" && metadata?.sessionSummary) {
        return <SessionSummaryHeader metadata={metadata.sessionSummary} isLastMessage={isLastMessage} />;
    }
}
