import { Button, type ComboboxItem, Flex, Group, Highlight, Progress, Select, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useRecordedLegalDisclosure } from "@expert/contentful/src/hooks";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { MessageBubbleIcon, PlayIcon, QuotationMarkIcon, SpeakerIcon } from "../../home-product/components/Icons";
import { type EligibleProduct } from "../../home-product";
import { type AudioType, isVoiceTask, useAgentSdk, useMostRecentTask, useSession, type VoiceTask } from "../../../sdk";
import { useAnalytics } from "../../../analytics";
import { AudioPlayingAnimation } from "../../home-product/components/Icons/AudioPlaying";
import { DisclosureModal } from "../components/DisclosureModal";
import { EnrollmentCard } from "../components/EnrollmentCard";
import { useDisclosureStepStore } from "../store/DisclosureStepStore";
import classes from "./RecordedLegalDisclosures.module.css";

export function RecordedLegalDisclosures({ product }: { product: EligibleProduct }): JSX.Element {
    const mostRecentTask = useMostRecentTask();
    const agentSdk = useAgentSdk();
    const [disclosureEndTime, setDisclosureEndTime] = useDisclosureStepStore((state) => [
        state.recordedDisclosureEndTime,
        state.setRecordedDisclosureEndTime,
    ]);
    const [disclosureLength, setDisclosureLength] = useState(0);
    const [isLoadingDisclosure, setIsLoadingDisclosure] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);
    const session = useSession();
    const { dispatcher } = useAnalytics();
    const task = mostRecentTask as VoiceTask;

    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }

    const { totalSeconds, restart, isRunning, pause } = useTimer({
        expiryTimestamp: disclosureEndTime
            ? new Date(disclosureEndTime)
            : new Date(Date.now() + 1000 * disclosureLength),
        autoStart: !!disclosureEndTime,
    });

    const languageStateItems: { value: AudioType; label: string }[] = [
        { value: "PreRecordedDisclosures", label: "English" },
        { value: "PreRecordedDisclosuresSpanish", label: "Spanish" },
    ] as const;

    const [languageState, setLanguageState] = useState<ComboboxItem>(
        mostRecentTask.locale === "es-mx" ? languageStateItems[1] : languageStateItems[0],
    );

    const url = useRecordedLegalDisclosure(product.productSku, languageState.label === "English" ? "en-US" : "es-US");

    const playLegalDisclosure = async () => {
        setIsLoadingDisclosure(true);
        void dispatcher.dispatchBusinessEvent("PlayLegalDisclosure_Clicked", { selectedLanguage: languageState.label });
        try {
            await agentSdk.playAudioInConference(task, languageState.value as unknown as AudioType, task.partner);
            const endTime = new Date(Date.now() + 1000 * disclosureLength);
            restart(endTime, true);
            setDisclosureEndTime(endTime);
        } catch (e) {
            /* empty */
        }
        setIsLoadingDisclosure(false);
    };

    useEffect(() => {
        if (task.status !== "assigned" || !task.customerCallId) {
            pause();
        }
    }, [pause, task.customerCallId, task.status]);

    useEffect(() => {
        if (!url) {
            return;
        }

        const audio = new Audio(url);

        audio.addEventListener("loadedmetadata", () => {
            setDisclosureLength(Math.floor(audio.duration));
        });
    }, [url]);

    return (
        <Flex h="100%" justify="center" direction="column">
            <Flex direction="column" className={classes.offerBox}>
                <Flex direction="column" p="1rem">
                    <Group grow preventGrowOverflow={false} wrap="nowrap">
                        <Highlight
                            size="24px"
                            highlightStyles={{
                                color: "var(--mantine-color-success-5)",
                                backgroundColor: "transparent",
                            }}
                            highlight="Play"
                        >
                            Play the disclosures
                        </Highlight>

                        {/* Only show language options for spanish-speaking customers */}
                        {mostRecentTask.locale === "es-mx" && (
                            <Select
                                allowDeselect={false}
                                comboboxProps={{ withinPortal: false }}
                                data={languageStateItems}
                                leftSection={<SpeakerIcon />}
                                onChange={(_value, option) => setLanguageState(option)}
                                style={{ maxWidth: "130px" }}
                                value={languageState.value}
                                withCheckIcon={false}
                            />
                        )}
                    </Group>

                    <>
                        <Text mt="24px" size="14px">
                            Tell the customer
                        </Text>
                        <Flex justify="space-between" mt="10px">
                            <QuotationMarkIcon />
                            <Text maw="89%" fw={700} size="16px">
                                I’m going to play you a short recording with important plan disclosures. During this, we
                                will stay on the line together and then I’ll finish your enrollment
                            </Text>
                        </Flex>
                    </>
                    {isRunning ? (
                        <>
                            <Flex className={classes.timeMarkers} mt="3rem" w="100%">
                                <Text size="12px" c="dark.1">
                                    {disclosureLength - totalSeconds >= 10
                                        ? `0:${disclosureLength - totalSeconds}`
                                        : `0:0${disclosureLength - totalSeconds}`}
                                </Text>
                                <Text size="12px" c="dark.1">
                                    0:{disclosureLength}
                                </Text>
                            </Flex>
                            <Progress
                                className={classes.progressBar}
                                maw="90%"
                                value={Math.floor(((disclosureLength - totalSeconds) / disclosureLength) * 100)}
                                color="#38D9A9"
                                transitionDuration={2000}
                            />
                        </>
                    ) : null}

                    <Flex style={{ alignSelf: "end" }} w="58%" mt="1rem">
                        <Flex>
                            <Button
                                disabled={
                                    session.currentTask?.status !== "assigned" || isRunning || !task.customerCallId
                                }
                                className={classes.playbackButton}
                                color="primary.6"
                                loading={isLoadingDisclosure}
                                radius="xl"
                                size="lg"
                                p="10px"
                                w="3.125rem"
                                variant="filled"
                                onClick={() => void playLegalDisclosure()}
                            >
                                {isRunning ? <AudioPlayingAnimation /> : <PlayIcon />}
                            </Button>
                        </Flex>
                        <Button
                            onClick={() => {
                                open();
                                void dispatcher.dispatchBusinessEvent("LegalDisclosureText_Clicked");
                            }}
                            ml="auto"
                            radius="xl"
                            size="lg"
                            p="10px"
                            w="3.125rem"
                            variant="transparent"
                        >
                            <MessageBubbleIcon />
                        </Button>
                    </Flex>
                </Flex>
                {mostRecentTask.status === "assigned" &&
                task.customerCallId &&
                disclosureEndTime &&
                new Date().getTime() >= disclosureEndTime ? (
                    <EnrollmentCard product={product} />
                ) : null}
            </Flex>
            <DisclosureModal
                opened={opened}
                close={close}
                productSku={product.productSku}
                language={languageState.label === "English" ? "en-US" : "es-US"}
            />
        </Flex>
    );
}
