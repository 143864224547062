import { Flex, Group, Text, type TextProps } from "@mantine/core";
import { MarkdownViewer } from "@expert/solve-tools";
import { NotesStarIcon } from "../../../assets/NotesStarIcon";
import type { SessionSummaryMetadata } from "../../../shared-types";

const headings = ["Call details", "Action taken", "Call conclusion", "Follow ups", "Additional info"];
function HeadingText(props: TextProps) {
    return <Text size="sm" fw={700} {...props} pt="sm" />;
}
export function generateSessionSummaryItem(metadata: SessionSummaryMetadata) {
    // regex to find occurrences of headings & inject heading4 markdown for styling purposes
    const headingRegex = new RegExp(headings.join("|"), "gi");
    const summaryWithMarkdown = metadata.summary.replace(headingRegex, "#### $&");
    const mt = metadata.summary.includes(headings[0]) ? "-0.5rem" : "0";
    return (
        <Flex direction="column" miw="10rem" mt={mt}>
            <MarkdownViewer content={summaryWithMarkdown} options={{ overrides: { h4: HeadingText } }} />
        </Flex>
    );
}

export function SessionSummaryHeader({
    metadata,
    isLastMessage = true,
}: {
    metadata: SessionSummaryMetadata;
    isLastMessage: boolean;
}) {
    return (
        <Group
            gap="xs"
            p="0.5rem 1rem"
            bg={isLastMessage ? "linear-gradient(to right, #8223d2, #5034cf)" : "var(--mantine-color-dark-4)"}
            styles={{ root: { "border-top-left-radius": "1.25rem", "border-top-right-radius": "1.25rem" } }}
        >
            <NotesStarIcon />
            <Text size="sm" fw="700">
                {metadata.header}
            </Text>
        </Group>
    );
}
