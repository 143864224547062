import { type CaseDetails, type Partner } from "../../../shared-types";
import type { Session } from "../../sessions/types";
import { type AgentSdk } from "../agentSdk";
import { type Task } from "../task";
import {
    type AudioType,
    type CallbackState,
    type TaskCompletedReason,
    type WrappingEndReason,
    type WrappingStartReason,
    type AgentActivity,
} from "../types";
import { type UpdateParticipantOptions } from "../voice/conferenceTypes";

function ensureAllFulfilled<T>(results: PromiseSettledResult<T>[]) {
    const reject = results.find((x): x is PromiseRejectedResult => x.status === "rejected");
    if (reject) {
        throw reject.reason;
    }
}

export class OrchestratorAgentSdk implements AgentSdk {
    /**
     * @description this class manages multiple sdks the priority of the sdks is determines by the order of them.
     * priority is important for handling conflicts such as conflicting agent activities.
     * @param {AgentSdk[]} agentSdks the sdks the orchestrator will be managing, non-empty array, order determines priority
     */
    constructor(readonly agentSdks: AgentSdk[]) {
        if (!agentSdks.length) {
            throw new Error("agentSdks has to have at least one sdk");
        }
    }

    public logout(): void {
        for (const sdk of this.agentSdks) {
            sdk.logout?.();
        }
    }

    private getExactlyOneSupportingSDK(task: Task) {
        const { 0: taskSupportingSDK, length } = this.agentSdks.filter((x) => x.canHandleTaskType(task));
        if (length !== 1) {
            throw new RangeError(`Task is supported by ${length} registered SDKs and should be supported by only one`);
        }

        return taskSupportingSDK;
    }

    public canHandleTaskType(task: Task): boolean {
        return this.agentSdks.some((x) => x.canHandleTaskType(task));
    }

    public getDefaultAgentActivity() {
        const defaults = new Set<AgentActivity>();
        for (const agentSdk of this.agentSdks) {
            defaults.add(agentSdk.getDefaultAgentActivity());
            if (defaults.size > 1) throw new Error("agent SDKs differ in their default agent activities");
        }
        const [result] = defaults;
        return result;
    }

    public async setAgentActivity(activity: AgentActivity): Promise<void> {
        const results = await Promise.allSettled(this.agentSdks.map((x) => x.setAgentActivity(activity)));

        ensureAllFulfilled(results);
    }

    public getAgentActivity(): AgentActivity {
        return this.agentSdks.map((x) => x.getAgentActivity()).find((x) => x !== "Available") ?? "Available";
    }

    public getPartners(): ReadonlySet<Partner> {
        const set = new Set<Partner>();
        for (const sdk of this.agentSdks) {
            const sdkPartners = sdk.getPartners();
            sdkPartners.forEach((partner) => set.add(partner));
        }

        if (set.size === 0) {
            throw new Error("Orchestrator was unable to find any valid partner.");
        }

        return set;
    }

    public async acceptTask(task: Task): Promise<void> {
        if (this.agentSdks.some((x) => x.getAgentActivity() !== "Available")) {
            throw new Error("AgentSdks have to be available to accept the call");
        }

        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);

        // Set to a custom busy status. (e.g. busy - other platform)
        const activityResults = await Promise.allSettled(
            this.agentSdks.filter((x) => x !== taskSupportingSDK).map((x) => x.setAgentActivity("Busy")),
        );
        ensureAllFulfilled(activityResults);

        await taskSupportingSDK.acceptTask(task);
    }

    public async rejectTask(task: Task): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.rejectTask(task);
    }

    public async wrapupTask(task: Task, reason: string): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.wrapupTask(task, reason);
    }

    public async completeTask(task: Task, reason: TaskCompletedReason): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);

        const activityResults = await Promise.allSettled(
            this.agentSdks.filter((x) => x !== taskSupportingSDK).map((x) => x.setAgentActivity("Available")),
        );
        ensureAllFulfilled(activityResults);

        await taskSupportingSDK.completeTask(task, reason);
    }

    public async cancelTask(task: Task): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.cancelTask(task);
    }

    public async hangupCall(task: Task, reason: string): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.hangupCall(task, reason);
    }

    public async sendToSurvey(task: Task, program: string, surveyId: string): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.sendToSurvey(task, program, surveyId);
    }

    public async holdCall(task: Task): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.holdCall(task);
    }
    public async resumeCall(task: Task): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.resumeCall(task);
    }

    public async muteCall(task: Task, shouldMute: boolean): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.muteCall(task, shouldMute);
    }

    public async sendDtmfDigits(digits: string): Promise<void> {
        const activityResults = await Promise.allSettled(this.agentSdks.map((x) => x.sendDtmfDigits(digits)));
        ensureAllFulfilled(activityResults);
    }

    public async redirectCallToTFN(task: Task, redirectTo: string): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.redirectCallToTFN(task, redirectTo);
    }

    public async redirectCallToSIP(task: Task, redirectTo: string): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.redirectCallToSIP(task, redirectTo);
    }

    public async addConferenceParticipant(
        task: Task,
        name: string,
        to: string,
        isSip?: boolean,
        sipParams?: Record<string, string>,
    ): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.addConferenceParticipant(task, name, to, isSip, sipParams);
    }
    public async updateParticipant(task: Task, name: string, options: UpdateParticipantOptions): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.updateParticipant(task, name, options);
    }
    public async removeParticipant(task: Task, callId: string): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.removeParticipant(task, callId);
    }
    public async removePendingParticipant(task: Task, callId: string): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.removePendingParticipant(task, callId);
    }
    public async leaveConference(task: Task): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.leaveConference(task);
    }
    public async endConference(task: Task): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.endConference(task);
    }

    // Should we remove it from the interface and keep it in the SdkBase? interfaces are usually meant for public methods
    protected createCustomerCallbackForTask(_task: Task, _callbackState: CallbackState): Promise<string> {
        throw new Error("Method not implemented.");
    }

    // Should we remove it from the interface and keep it in the SdkBase? interfaces are usually meant for public methods
    protected createOutboundCall(_callbackMdn: string): Promise<string> {
        throw new Error("Method not implemented.");
    }

    public completeSession(partner: Partner): void {
        this.agentSdks.forEach((x) => x.completeSession(partner));
    }

    public async scheduleCallbackNow(callbackDelay: number, callbackMdn: string): Promise<boolean> {
        const [primarySdk] = this.agentSdks;
        return await primarySdk.scheduleCallbackNow(callbackDelay, callbackMdn);
    }

    public async callCustomer(mdn: string, options?: { extension?: string | undefined }): Promise<void> {
        const [primarySdk] = this.agentSdks;
        await primarySdk.callCustomer(mdn, options);
    }

    public async startWrapup(reason: WrappingStartReason): Promise<void> {
        const [primarySdk] = this.agentSdks;
        await primarySdk.startWrapup(reason);
    }

    public async endWrapup(reason: WrappingEndReason, partner: Partner): Promise<void> {
        const [primarySdk] = this.agentSdks;
        await primarySdk.endWrapup(reason, partner);
    }

    public async callCustomerBack(session: Session): Promise<void> {
        const [primarySdk] = this.agentSdks;
        await primarySdk.callCustomerBack(session);
    }

    public async updateCaseDetails(task: Task, caseDetailsUpdates: Partial<CaseDetails>): Promise<void> {
        const [primarySdk] = this.agentSdks;
        await primarySdk.updateCaseDetails(task, caseDetailsUpdates);
    }

    public getCaseDetails(task: Task): CaseDetails | undefined {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        return taskSupportingSDK.getCaseDetails(task);
    }

    public async playAudioInConference(task: Task, audioType: AudioType, partner: Partner): Promise<void> {
        const taskSupportingSDK = this.getExactlyOneSupportingSDK(task);
        await taskSupportingSDK.playAudioInConference(task, audioType, partner);
    }
}
