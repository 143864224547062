import styled from "@emotion/styled";

interface LoaderProps {
    width?: string | number;
    height?: string | number;
}

/** FIXME: remove styled */
const StyledSvg = styled.svg`
    display: block;
    width: 100%;
    height: 100%;

    #eSOCNY03Vh62_tr {
        animation: eSOCNY03Vh62_tr__tr 4000ms linear infinite normal forwards;
    }

    @keyframes eSOCNY03Vh62_tr__tr {
        0% {
            transform: translate(12px, 11.982764px) rotate(0deg);
        }
        100% {
            transform: translate(12px, 11.982764px) rotate(1440deg);
        }
    }

    #eSOCNY03Vh63 {
        animation: eSOCNY03Vh63_s_do 4000ms linear infinite normal forwards;
    }

    @keyframes eSOCNY03Vh63_s_do {
        0% {
            stroke-dashoffset: 120;
            animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        }
        50% {
            stroke-dashoffset: 0;
            animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
        }
        100% {
            stroke-dashoffset: 119;
        }
    }
`;
/** FIXME: remove styled */
const SvgContainer = styled.div<{ width: string | number; height: string | number }>`
    position: relative;
    vertical-align: middle;
    margin: auto;
    width: ${({ width }) => width};
    height: ${({ width }) => width};
`;

export function AsurionLoadingSpinner({ width = "100%", height = "100%", ...rest }: LoaderProps): JSX.Element {
    return (
        <SvgContainer data-aui="loader" height={height} width={width}>
            <StyledSvg
                aria-describedby="loader-title"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                {...rest}
            >
                <g id="eSOCNY03Vh62_tr" transform="translate(12,11.982764) rotate(0)">
                    <g transform="scale(0.374102,0.374102) translate(-172.009537,-224.748871)">
                        <path
                            d="M16.740383,-21.819392C23.282934,-16.792264,27.5,-8.888607,27.5,0C27.5,15.187831,15.187831,27.5,0,27.5s-27.5-12.312169-27.5-27.5c0-11.228019,6.728981-20.884399,16.374237-25.156435"
                            fill="rgba(84,131,204,0)"
                            id="eSOCNY03Vh63"
                            stroke="var(--mantine-color-dark-3)"
                            strokeDasharray="143.36"
                            strokeDashoffset="120"
                            strokeLinecap="square"
                            strokeWidth="6"
                            transform="translate(172.009536 224.748878)"
                        />
                    </g>
                </g>
            </StyledSvg>
        </SvgContainer>
    );
}
